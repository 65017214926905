// react library
import { Route, Routes, useLocation } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux slices
import { setError, setLoading, setUser } from "./redux/slice/Auth";
import { fetchFollowerFollowing,fetchUserPodCast,setID } from "./redux/slice/profile"

// api
import api from "./utils/api";

// pages and Protected Route
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Profile from "./pages/routes/Profile";
import Home from "./pages/routes/Home";
import Login from "./pages/routes/Login";
import Create from "./pages/routes/Create";
import Library from "./pages/routes/Library";
import Waitlist from "./pages/routes/waitlist";
import UserProfile from "./pages/routes/UserProfile";
import Terms from "./pages/routes/Terms";
import Privacy from "./pages/routes/Privacy";
import Listen from "./pages/routes/listen";
import SettingsPage from "./pages/routes/Settings";

// auth
import { auth } from "./firebase/init";
import { onAuthStateChanged } from "firebase/auth";
import { fetchTrendingPodcastThunk } from "./redux/slice/podcast/TrendingPodcast";
import { fetchPopularPodcastThunk } from "./redux/slice/podcast/PopularPodcast";
import { fetchUserPodcastThunk } from "./redux/slice/podcast/UserPodcast";
import { fetchPopularPeopleThunk } from "./redux/slice/people/popularPeople";
import { fetchUserFollow } from "./redux/slice/follow/userFollowerFollowing";
import PopularPeople from "./pages/routes/PopularPeople";
import PopularPodcast from "./pages/routes/PopularPodcast";
import TrendingPodcast from "./pages/routes/TrendingPodcast";




// component
function App() {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  useEffect(() => {
    if (mode === "manuall" || pathname === "/login" || pathname==="/") {
      console.log("turning off");
      return;
    }

    dispatch(setLoading({ loading: true }));
    const unSubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          // fetch user based on its uid
          api
            .post("/auth/check-or-insert", {
              emailID: user.email,
            })
            .then((res) => {
              const {
                emailID: e,
                imageUrl,
                userName,
                id,
                userChatUrl,
              } = res.data;

              dispatch(
                setUser({
                  user: { email: e, imageUrl, userName, id, userChatUrl },
                })
              );
              dispatch(setID({ id }));
              
              // fetch user info
              dispatch(fetchFollowerFollowing({ userID: id }));
              dispatch(fetchUserPodCast({ userID: id }));
              dispatch(fetchPopularPodcastThunk({userID: id}))
              dispatch(fetchUserPodcastThunk({userID: id}))
              dispatch(fetchTrendingPodcastThunk({userID: id}))
              dispatch(fetchPopularPeopleThunk())
              dispatch(fetchUserFollow({userID : id}))
              // if (userChatUrl && !localStorage.getItem("disable-chat-fetch")) {
              //   dispatch(syncChat(userChatUrl));
              // }
            })
            .catch((err) => {
              dispatch(setError({ error: err.message }));
            });
        } else {
          dispatch(setLoading({ loading: false }));
        }
      },
      (error) => {
        dispatch(setError({ error: error.message }));
      }
    );

    return () => unSubscribe();
  }, [mode]);

  return (
    <Fragment>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/" element={<Waitlist />} />

        {/* protected route */}
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/library" element={<Library />} />
          <Route path="/create" element={<Create />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/popular-people" element={<PopularPeople />} />
          <Route path="/popular-podcast" element={<PopularPodcast />} />
          <Route path="/trending-podcast" element={<TrendingPodcast />} />
        </Route>

        {/* personal links */}
        <Route path="/user/:id" element={<UserProfile />} />
        <Route path="/listen/:id" element={<Listen />} />
      </Routes>
    </Fragment>
  );
}

export default App;
