import api from "./api";
import { data } from "../assets/Data";
import { setCookie } from "./cookie";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/init";
import { notify } from "./notifyHarshit";
import { Pi } from "lucide-react";
import { redirect } from "react-router-dom";

export async function upsertUser({
  uid,
  email,
  displayName,
  photoURL,
  provider,
}) {
  // get user id from db
  let user;
  try {
    user = (await api.post("/auth/check-or-insert", { emailID: email })).data;
  } catch (err) {
    console.log(err);
    throw err;
  }

  // setCookie("access-token", result1.userToken);
  setCookie("userID", user.id);


  // sync user info if missing
  let missingInfo = null;

  if (!user?.emailID) {
    if (!missingInfo) missingInfo = {};
    missingInfo.emailId = email;
  }
  if (!user?.userName) {
    if (!missingInfo) missingInfo = {};
    missingInfo.userName = displayName;
  }
  if (!user?.imageUrl) {
    if (!missingInfo) missingInfo = {};
    missingInfo.imageUrl = photoURL || "/avatar.png";
  }

  user.imageUrl = user.imageUrl || "/avatar.png"

  if (!missingInfo) {
    return { id: user.id, ...user, email };
  }

  try {
    const updatedUSer = (
      await api.post("/user/update-profile", {
        id: user.id,
        userData: {
          emailID: email,
          userName: displayName,
          imageUrl: photoURL || "/avatar.png",
        },
      })
    ).data;

    return { id: user.id, ...updatedUSer.result.Attributes, email };
  } catch (err) {
    console.log(err);
    throw err;
  }
}


export async function fetchPodcastFromId(id) {
  try {
    const podcast = (await api.post("/userpodcast/podcast-details-by-id", {
      podcastID: id,
    })).data.user[0];

    return podcast;
  } catch (err) {
    throw err;
  }
}

export async function followUser(payload) {
  try{
    const followData = (await api.post("/userfollower/store-follower", payload)).data.userFollowerData;
    return followData;
  }
  catch(err){
    throw err;
  }
}

export async function fetchFollowFollowing({userID}){
  const promise = [
    (async function (){
      try{
        // listeners
        const data = await api.post("/userfollower/get-followers-list", {followingID : userID})
        return data.data.followers;
      }
      catch(err){
        throw err;
      }
    })(),
    (async function (){
      try{
        // subscription
        const data = await api.post("/userfollower/get-following-list", {followerID : userID})
        return data.data.followers;
      }
      catch(err){
        throw err;
      }
    })()
  ]

  try{
    const a = await Promise.all(promise);
    const [follower, following] = a;
    return {follower, following}
  }
  catch(err){
    throw err;
  }
}

// fetch trending podcast
export async function fetchTrendingPodcast({userID}){
  if (process.env.NODE_ENV === "development"){
    userID = "a7403d30-0e32-4ab4-82e2-3643sd379c0fa"
  }
  try{
    const data = (await api.post("/userpodcast/get-user-podcasts-trendings", {userID})).data;
    return data;
  }
  catch(err){
    throw err;
  }
}
export async function fetchPopularPodcast({userID}){
  if (process.env.NODE_ENV === "development"){
    userID = "a7403d30-0e32-4ab4-82e2-3643sd379c0fa"
  }
  try{
    const data = (await api.post("/userpodcast/get-user-podcasts-popular", {userID})).data;
    return data;
  }
  catch(err){
    throw err;
  }
}

export async function fetchUserPodcast({userID}){
  try{
    const data = (await api.post("/userpodcast/get-user-podcasts", {userID})).data;
    return data;
  }
  catch(err){
    throw err;
  }
}


// 
export async function fetchPopularPeople(){
  try{
    const res = await api.post("/user/get-popular-list")
    return res.data;
  }
  catch(err){
    throw err;
  }
}