import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPopularPeople } from "../../../utils/api-helper";

export const fetchPopularPeopleThunk = createAsyncThunk(
  "fetchPopularPeople",
  fetchPopularPeople
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const PopularPeople = createSlice({
  name: "popularPeople",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchPopularPeopleThunk.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPopularPeopleThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchPopularPeopleThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });
  },
});

export default PopularPeople.reducer;
