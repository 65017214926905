import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserPodcast } from "../../../utils/api-helper";

export const fetchUserPodcastThunk = createAsyncThunk(
  "fetchUserPodcastThunk",
  fetchUserPodcast
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const UserPodcastSlice = createSlice({
  name: "userPodcast",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchUserPodcastThunk.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserPodcastThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchUserPodcastThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });
  },
});

export default UserPodcastSlice.reducer;
