import React from "react";
import { useDispatch } from "react-redux";
import {setAudioPlayerStatus} from "../../redux/slice/Appconfig"
import {setCurrentPodCast} from "../../redux/slice/podcast/currentPlaingPodcast"
function PodcastListItem(props) {
  const { id, title, imageUrl, description } = props;
  const dispatch = useDispatch()
  function handlOpenAudioPlayer(){
    dispatch(setAudioPlayerStatus({status : "fullscreen"}))
    dispatch(setCurrentPodCast(props))

    console.log(props)
  }

  return (
    <div onClick={handlOpenAudioPlayer} className="flex flex-col">
      <img
        className="flex-shrink-0 object-cover min-w-44 h-56 rounded-xl "
        src={imageUrl || "default.png"}
      />
      <span className="mt-2 text-lg">{title || "Podcast Title"}</span>
      <span className="mt-2">
        {description
          ? description?.split(" ").slice(0, 2).join(" ")
          : "podcast desc"} 
        ...
      </span>
    </div>
  );
}

export default PodcastListItem;
