import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";
export const fetchUserProfileData = createAsyncThunk(
  "fetchUserProfileData",
  async function ({ userID }) {
    try {
      const user = (await api.post("/user/user-details-by-id", { userID })).data
        .user;
      return user;
    } catch (err) {
      throw err;
    }
  }
);

const initialState = {
  userData: {
    imageUrl: "",
    email: "",
    userName: "",
  },
  loading: false,
  error: null,
};

const ProfileSlice = createSlice({
  name: "user-profile",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfileData.pending, (state, action) => {
      state.userData = {
        imageUrl: "",
        email: "",
        userName: "",
      };
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserProfileData.rejected, (state, action) => {
      state.loading = false;
      state.userData = {
        imageUrl: "",
        email: "",
        userName: "",
      };
      state.error = action.error.message;
    });
    builder.addCase(fetchUserProfileData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.userData = action.payload;
    });
  },
});

export default ProfileSlice.reducer;
