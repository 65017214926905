import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFollowFollowing } from "../../../utils/api-helper";

export const fetchFollowerFollowing = createAsyncThunk(
  "fetchUserFollower",
  fetchFollowFollowing
);

const initialState = {
  loading: false,
  error: null,
  followers: [],
  following: [],
};

const FollowSlice = createSlice({
  name: "user-follows",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFollowerFollowing.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchFollowerFollowing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchFollowerFollowing.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.followers = action.payload.follower;
      state.following = action.payload.following;
    });
  },
});

export default FollowSlice.reducer;
