import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFollowFollowing } from "../../../utils/api-helper";

export const fetchUserFollow = createAsyncThunk(
  "fetchFollowFollowingThunk",
  fetchFollowFollowing
);

const initialState = {
  following: [],
  followers : [],
  loading: false,
  error: null,
};

const userFollowFollowingSlice = createSlice({
  name: "followFollowingSlice",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchUserFollow.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserFollow.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchUserFollow.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.followers = action.payload.follower;
      state.following = action.payload.following;
    });
  },
});

export default userFollowFollowingSlice.reducer;
