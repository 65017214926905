import React, { Fragment } from "react";
import { ChevronLeft, ChevronRight, Music, UserPlus, X } from "lucide-react";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import PodcastListItem from "../../components/ui/PodcastListItem";


const Home = () => {
  return (
    <Fragment>
      <Navbar nav search />
      <section className="overflow-y-scroll mt-4 rounded-xl scroll-none grow min-h-0">
        <PodcastDisplay Title={"Podcast"} type="myPodcast" url="podcast" />
        <PodcastDisplay Title={"Trending"} type="trendingPodcast" url="/trending-podcast" />
        <PodcastDisplay Title={"Popular"} type="popularPodcast" url="/popular-podcast" />
        <PopularPeople  />
      </section>
      <Link
        to="/create"
        className="text-2xl mt-4 rounded-full bg-gradient-to-r from-[#40c9ff] to-[#e81cff] text-white justify-center py-4 flex gap-2 items-center"
      >
        <Music size={20} />
        create
      </Link>
    </Fragment>
  );
};



function PodcastDisplay({ type, url, Title }) {
  const { data, loading, error } = useSelector((state) => state[type]);

  if (loading) {
    return (
      <section className="mt-6">
        <div className="bg-gray  animate-pulse h-6 w-20 rounded-full" />
        <div className="flex justify-between gap-4">
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray  animate-pulse rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse rounded-md " />
            <div className="bg-gray animate-pulse  h-6 w-20 rounded-full" />
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse  rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse  rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <pre>error</pre>;
  }

  return (
    <section className="flex gap-4 flex-col mt-6 oberflow-x-hidden">
      <span className="text-neutral-300 flex items-center justify-between">
        <p className="text-xl">{Title}</p>
        <Link to={url} className="flex items-center gap-2">
          show more
          <ChevronRight />
        </Link>
      </span>
      <div className="flex overflow-x-scroll gap-4 scroll-none">
        {data.length > 0 ? (
          data.map((item, index) => (
            <PodcastListItem key={item.id} {...item}/>
          ))
        ) : (
          <span>no {Title.toLowerCase()} podcast for this user</span>
        )}
      </div>
    </section>
  );
}

function PopularPeople(){
  const { data, loading, error } = useSelector((state) => state.populars);

  if (loading) {
    return (
      <section className="mt-6">
        <div className="bg-gray  animate-pulse h-6 w-20 rounded-full" />
        <div className="flex justify-between gap-4">
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray  animate-pulse rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse rounded-md " />
            <div className="bg-gray animate-pulse  h-6 w-20 rounded-full" />
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse  rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
          <div className="flex-col grow flex gap-4 mt-4">
            <div className="w-full h-56 bg-gray animate-pulse  rounded-md " />
            <div className=" animate-pulse bg-gray h-6 w-20 rounded-full" />
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <pre>error</pre>;
  }

  return (
    <section className="flex gap-4 flex-col mt-6 oberflow-x-hidden">
      <span className="text-neutral-300 flex items-center justify-between">
        <p className="text-xl">Popular People</p>
        <Link to="/popular-people" className="flex items-center gap-2">
          show more
          <ChevronRight />
        </Link>
      </span>
      <div className="flex overflow-x-scroll gap-4 scroll-none">
        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="flex flex-col items-center" key={item.id}>
              <img
                className="flex-shrink-0 object-cover min-w-44 h-44 rounded-full "
                src={item.image || "default.png"}
              />
              <span className="mt-2 text-lg">{item.name}</span>
            </div>
          ))
        ) : (
          <span>no trending podcast for this user</span>
        )}
      </div>
    </section>
  );
}

export default Home;
