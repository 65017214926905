import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import { fetchTrendingPodcast } from "../../../utils/api-helper";


export const fetchTrendingPodcastThunk = createAsyncThunk(
  "fetchTrendingPodcast",
  fetchTrendingPodcast
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const TrendingPodcastSlice = createSlice({
  name: "trendingPodcast",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchTrendingPodcastThunk.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTrendingPodcastThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchTrendingPodcastThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });
  },
});

export default TrendingPodcastSlice.reducer;
