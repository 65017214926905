import { Fragment } from "react";
import { ChevronLeft } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Music } from "lucide-react";

function PopularPeople() {
  const { data, loading, error } = useSelector((state) => state.populars);
  if (loading) {
    return (
      <Fragment>
        <section className="mt-6 animate-pulse">
          <div className="flex items-center">
            <div className="bg-gray size-8 rounded-full" />
            <div className="mx-auto h-8 w-36 bg-gray rounded-full" />
          </div>
          <div className="grid gap-4 grid-cols-2 mt-8">
            <div className="flex flex-col gap-4 items-center">
              <div className="size-44 rounded-full bg-gray" />
              <div className="w-20 h-8 rounded-full bg-gray" />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="size-44 rounded-full bg-gray" />
              <div className="w-20 h-8 rounded-full bg-gray" />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="size-44 rounded-full bg-gray" />
              <div className="w-20 h-8 rounded-full bg-gray" />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="size-44 rounded-full bg-gray" />
              <div className="w-20 h-8 rounded-full bg-gray" />
            </div>
          </div>
        </section>
        <div className="h-16  animate-pulse rounded-full w-full bg-gray mt-auto" />
      </Fragment>
    );
  }
  if (error) {
    return <pre>error</pre>;
  }
  return (
    <Fragment>
      <nav className="flex items-center">
        <Link to="/home" className="bg-gray w-fit rounded-full p-2">
          <ChevronLeft />
        </Link>
        <span className="mx-auto text-xl antialiased">People</span>
      </nav>
      <section className="grid grid-cols-2 gap-8 overflow-y-scroll my-4 scroll-none  rounded-xl">
        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="flex flex-col items-center" key={item.id}>
              <img
                className="flex-shrink-0 object-cover size-40 rounded-full "
                src={item.image || "default.png"}
              />
              <span className="mt-2 text-xl">{item.name}</span>
            </div>
          ))
        ) : (
          <span>no trending podcast for this user</span>
        )}
      </section>
      <Link
        to="/create"
        className="text-2xl mt-4 rounded-full bg-gradient-to-r from-[#40c9ff] to-[#e81cff] text-white justify-center py-4 flex gap-2 items-center"
      >
        <Music size={20} />
        create
      </Link>
    </Fragment>
  );
}

export default PopularPeople;
