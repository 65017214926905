import React, { Fragment, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import {
  LoaderCircle,
  Music,
  EllipsisVertical,
  Share2,
  LogOut,
  X,
} from "lucide-react";

import { Link } from "react-router-dom";

import PodcastListItem from "../../components/ui/PodcastListItem";

function Profile() {
  const { followers, following, loading, error } = useSelector(
    (state) => state.follow
  );
  return (
    <Fragment>
      <Navbar nav search />
      <UserImage />
      <Follow />

        <Podcast />

      <Link
        to="/create"
        className="text-2xl mt-4 rounded-full bg-gradient-to-r from-[#40c9ff] to-[#e81cff] text-white justify-center py-4 flex gap-2 items-center"
      >
        <Music size={20} />
        create
      </Link>
    </Fragment>
  );
}

function UserImage() {
  const { imageUrl, userName } = useSelector((state) => state.auth.user);
  return (
    <div className="mt-8 flex items-center gap-4 justify-center">
      <img src={imageUrl} className="bg-graylight size-28 rounded-full" />
      <div className="flex flex-col gap-4">
        <span className="font-bold text-[24px]">@{userName}</span>
      </div>
    </div>
  );
}

function Follow() {
  const { followers, following, loading, error } = useSelector(
    (state) => state.follow
  );
  if (loading) {
    return (
      <div className="grid grid-cols-2 gap-4 mt-4  h-fit">
        <div className="bg-gray h-12 animate-pulse py-3 text-center rounded-full" />
        <div className="bg-gray h-12 animate-pulse py-3 text-center rounded-full" />
      </div>
    );
  }
  if (error) {
    return <pre>error</pre>;
  }
  return (
    <section className="grid mt-4 text-lg grid-cols-2 gap-4">
      <div className="bg-gray py-3 text-center rounded-full">
        {followers.length} Listeners
      </div>
      <div className="bg-gray py-3 text-center rounded-full">
        {following.length} Subscribes
      </div>
    </section>
  );
}

function Podcast() {
  const { data, loading, error } = useSelector((state) => state.myPodcast);

  if (loading) {
    return (
      <Fragment>
        <div className="justify-between text-2xl flex mt-8 mb-4 mx-3">
          <div className="h-8 animate-pulse w-36 mr-auto rounded-full bg-gray" />
          <div className="h-8 animate-pulse w-8 rounded-full bg-gray" />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {[1, 1, 1, 1].map((item, index) => (
            <div key={index}>
              <div className="h-44 animate-pulse w-full rounded-xl bg-gray"/>
              <div className="flex gap-3">
                <div className="animate-pulse mt-2 rounded-full size-8 bg-gray"/>
                <div className="animate-pulse mt-2 rounded-full h-8 grow bg-gray"/>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
  if (error) {
    return <pre>error</pre>;
  }

  return (
    <Fragment>
      <div className="justify-between text-2xl flex mt-8 mb-4 mx-3">
        <span className="font-bold">Your Podcasts</span>
        <span className="font-bold">{data.length}</span>
      </div>
      <section className="overflow-y-scroll grow rounded-xl min-h-0 scroll-none grid grid-cols-2 gap-4 mt-4 mx-3">
        {data.map((item) => (
          <PodcastListItem key={item.id} {...item} />
        ))}
      </section>
    </Fragment>
  );
}
export default Profile;

// bf155468-fc75-43c5-8c1f-820400c04de9
