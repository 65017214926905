import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPopularPodcast } from "../../../utils/api-helper";
export const fetchPopularPodcastThunk = createAsyncThunk(
  "fetchPoularPodcast",
  fetchPopularPodcast
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const PopularPodcastSlice = createSlice({
  name: "popularPodcast",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchPopularPodcastThunk.pending, (state, action) => {
        state.loading = true;
        state.error = null;
    });
    builder.addCase(fetchPopularPodcastThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    });
    builder.addCase(fetchPopularPodcastThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
    });
  },
});


export default PopularPodcastSlice.reducer;
