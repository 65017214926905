import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import SideBar from "../../components/SideBar";
import AudioPlayer from "../../components/AudioPlayer";

import { Share2, LoaderCircle, CookingPot } from "lucide-react";

import { setAudioPlayerStatus } from "../../redux/slice/Appconfig";
import { setCurrentPodCast } from "../../redux/slice/podcast/currentPlaingPodcast";
import { fetchUserProfileData } from "../../redux/slice/user-profile/UserProfile";

import { followUser } from "../../utils/api-helper";

import FollowModal from "../../components/modal/followModal";

import { setData } from "../../redux/slice/follow/userFollowPreview";

function UserProfile() {
  const { userData, loading, error } = useSelector(
    (state) => state.userProfile
  );

  const { user: AuthUser, loading: loadingAuth } = useSelector(
    (state) => state.auth
  );

  const { id: userID } = useParams();
  const dispatch = useDispatch();
  // const { following, followers, loading: followloading, error } = follow;
  // const { data, error: errorPodcast, loading: loadingPodcast } = podcast;
  // const { id: myUserID } = useSelector((state) => state.profile);
  // const { imageUrl, email, userName } = userData;
  // const [loadingFollow, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfileData({ userID }));
    // dispatch(fetchFollowerFollowing({ userID }));
    // dispatch(fetchUserPodCast({ userID }));
  }, []);

  // const followModalRef = useRef();

  // async function Follow() {
  //   const payload = {
  //     followerID: myUserID,
  //     followerUserName: AuthUser.userName,
  //     followerUserProfile: AuthUser.imageUrl,
  //     followingID: userID,
  //     followingUserName: userName,
  //     followingUserProfile: imageUrl,
  //     followType: "follow",
  //     status: 1,
  //   };
  //   console.log(payload);
  //   setLoading(true);
  //   try {
  //     const data = await followUser(payload);
  //     dispatch(addFollower(data));
  //   } catch (error) {
  //     alert(error?.message);
  //     console.log(error);
  //   }
  //   setLoading(false);
  // }
  // const followed = AuthUser
  //   ? followers.some((item) => item.followerID === myUserID)
  //   : false;

  return (
    <Fragment>
      <AudioPlayer />
      <SideBar />
      <Navbar />
      <UserImage />
      <Follow />
    </Fragment>
  );
}

function UserImage() {
  const { userData, loading, error } = useSelector(
    (state) => state.userProfile
  );

  if (loading) {
    return (
      <div className="mt-8 animate-pulse flex items-center gap-4 justify-center">
        <div className="bg-gray size-28 rounded-full" />
        <div className="gap-4 h-8 rounded-full bg-gray w-44" />
      </div>
    );
  }

  if (error) {
    return <pre>{error?.message}</pre>;
  }
  return (
    <div className="mt-8 flex items-center gap-4 justify-center">
      <img
        src={userData.imageUrl}
        className="bg-graylight size-28 rounded-full"
      />
      <div className="flex flex-col gap-4">
        <span className="font-bold text-[24px]">@{userData.userName}</span>
      </div>
    </div>
  );
}

function Follow() {
  const { followers, following, loading, error } = useSelector(
    (state) => state.userFollow
  );
  if (loading) {
    return (
      <div className="grid grid-cols-2 gap-4 mt-4  h-fit">
        <div className="bg-gray h-12 animate-pulse py-3 text-center rounded-full" />
        <div className="bg-gray h-12 animate-pulse py-3 text-center rounded-full" />
      </div>
    );
  }
  if (error) {
    return <pre>{error}</pre>;
  }
  return (
    <section className="grid mt-4 text-lg grid-cols-2 gap-4">
      <div className="bg-gray py-3 text-center rounded-full">
        {followers.length} Listeners
      </div>
      <div className="bg-gray py-3 text-center rounded-full">
        {following.length} Subscribes
      </div>
    </section>
  );
}

export default UserProfile;
