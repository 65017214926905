import { Fragment, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Play,
  Pause,
  StepBack,
  StepForward,
  ChevronUp,
  ChevronDown,
  X,
  Copy,
} from "lucide-react";
import {
  setProgress,
  setIsPlaying,
} from "../redux/slice/podcast/currentPlaingPodcast";
import { setAudioPlayerStatus } from "../redux/slice/Appconfig";
import { useLocation, Link } from "react-router-dom";

function AudioPlayer() {
  const { url, duration, progress, id, title, hostName, cover, isPlaying, userID } =
    useSelector((state) => state.currentPodcast);
  const { audioPlayerStatus } = useSelector((state) => state.config);
  const playerRef = useRef();
  const dispatch = useDispatch();

  function playAudio() {
    if (playerRef.current) {
      playerRef.current.play();
      dispatch(setIsPlaying(true));
    }
  }
  function stopAudio() {
    if (playerRef.current) {
      playerRef.current.pause();
      dispatch(setIsPlaying(false));
    }
  }

  const handleStepForward = () => {
    if (!playerRef.current) return;
    const newTime = Math.min(progress + 20, duration);
    playerRef.current.currentTime = newTime;
    dispatch(setProgress(newTime));
  };

  const handleStepBack = () => {
    if (!playerRef.current) return;
    const newTime = Math.max(progress - 20, 0);
    playerRef.current.currentTime = newTime;
    dispatch(setProgress(newTime));
  };

  function seekto(time) {
    playerRef.current.currentTime = time;
    dispatch(setProgress(time));
  }



  if (!url || audioPlayerStatus === "closed") {
    return null;
  }
  const props = {
    playerRef,
    handleStepForward,
    handleStepBack,
    stopAudio,
    playAudio,
    url,
    duration,
    progress,
    id,
    title,
    hostName,
    cover: cover || "/default.png",
    isPlaying,
    seekto,
    userID,
    audioPlayerStatus,
  };

  return (
    <Fragment>
      <SmallPlayer {...props} />
      <BigPlayer {...props} />
      <audio
        ref={playerRef}
        onTimeUpdate={(e) => {
          const currentTime = e.target.currentTime;
          dispatch(setProgress(currentTime));
        }}
        src={url}
      />
    </Fragment>
  );
}

export default AudioPlayer;

function SmallPlayer({
  playerRef,
  handleStepForward,
  handleStepBack,
  stopAudio,
  playAudio,
  url,
  duration,
  progress,
  hostName,
  id,
  title,
  cover,
  isPlaying,
  audioPlayerStatus,
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  return (
    <div
      style={{
        display:
          audioPlayerStatus === "collapsed" &&
          (pathname === "/home" ||
            pathname === "/profile" ||
            pathname === "/library" ||
            pathname.startsWith("/user"))
            ? "block"
            : "none",
      }}
      className="fixed left-0 bottom-[2.5rem] w-full px-4"
    >
      <div className="flex max-w-md mx-auto flex-col bg-[#333333bb] h-fit backdrop-blur-lg p-4 rounded-lg ">
        <button
          onClick={(e) =>
            dispatch(setAudioPlayerStatus({ status: "fullscreen" }))
          }
          className="bg-[#ffffff28] p-1 absolute top-2 right-2 rounded-full text-gray-200 "
        >
          <ChevronUp size={15} />
        </button>

        <div className="flex">
          <section className="flex text-gray-100 gap-2">
            <img src={cover} className="size-10 rounded-lg" />
            <div className="flex flex-col gap-1 justify-center">
              <span className="font-bold text-xs">{title}</span>
              <span className="font-bold text-gray-400 text-xs">
                {hostName}
              </span>
            </div>
          </section>
          <section className="grow justify-evenly items-center text-gray-100 flex">
            <StepBack onClick={handleStepBack} size={30} />
            {isPlaying ? (
              <Pause onClick={stopAudio} size={30} />
            ) : (
              <Play onClick={playAudio} size={30} />
            )}
            <StepForward onClick={handleStepForward} size={30} />
          </section>
        </div>
        <div className="h-[2px] flex rounded-full translate-y-2 bg-[#ffffff1c]">
          <div
            style={{ width: `${(progress / duration) * 100}%` }}
            className="bg-[#ffffff50]"
          />
        </div>
      </div>
    </div>
  );
}

// https://static-content-for-text.s3.amazonaws.com/push-nofication-web.mp3

function BigPlayer({
  playerRef,
  handleStepForward,
  handleStepBack,
  stopAudio,
  playAudio,
  url,
  duration,
  progress,
  id,
  title,
  cover,
  hostName,
  isPlaying,
  seekto,
  userID,
  audioPlayerStatus,
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const profileURl = `/user/${userID}`;

  async function copy() {
    try {
      const domain = window.location.href.split("://")[1].split("/")[0];
      const url = `${
        process.env.NODE_ENV === "development" ? "http" : "https"
      }://${domain}/#/listen/${id}`;
      await navigator.clipboard.writeText(url);
      alert("url copied");
    } catch (err) {
      alert(err?.message);
    }
  }
  
  return (
    <div
      style={{
        display:
          audioPlayerStatus === "fullscreen" &&
          (pathname === "/home" ||
            pathname === "/profile" ||
            pathname === "/library" ||
            pathname.startsWith("/user"))
            ? "block"
            : "none",
      }}
      className="fixed top-0 left-0 w-[100dvw] p-4 h-[100dvh]"
    >
      <div className="bg-[#333333bb] size-full flex justify-start flex-col p-6 gap-4 backdrop-blur-2xl rounded-xl overflow-hidden">
        <button
          onClick={(e) =>
            dispatch(setAudioPlayerStatus({ status: "collapsed" }))
          }
          className="bg-[#ffffff28] p-1 absolute top-2 right-2 rounded-full text-gray-200 "
        >
          <ChevronDown size={25} />
        </button>
        <button
          onClick={(e) => dispatch(setAudioPlayerStatus({ status: "closed" }))}
          className="bg-[#ffffff28] p-1 absolute top-2 right-16 rounded-full text-gray-200 "
        >
          <X size={25} />
        </button>
        <div className="my-auto flex flex-col gap-4 items-center">
          <img src={cover} className="rounded-3xl self-center" />
          <h1 className="text-4xl  text-gray-200 ">{title}</h1>
          <Link
            to={profileURl}
            onClick={(e) => {
              dispatch(setAudioPlayerStatus({ status: "closed" }));
            }}
            className="text-2xl text-gray-500 "
          >
             {hostName}
          </Link>
        </div>
        <section className="justify-evenly items-center mt-auto text-gray-100 flex">
          <StepBack onClick={handleStepBack} size={30} />
          {isPlaying ? (
            <Pause onClick={stopAudio} size={30} />
          ) : (
            <Play onClick={playAudio} size={30} />
          )}
          <StepForward onClick={handleStepForward} size={30} />
        </section>
        <input
          type="range"
          min={0}
          max={duration}
          className="w-full"
          value={progress}
          onChange={(e) => seekto(e.target.value)}
        />
      </div>
    </div>
  );
}
