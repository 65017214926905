import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "./slice/Auth";
import Appconfig from "./slice/Appconfig";
import chatSlice from "./slice/chat";
import CurrentPodcastSlice from "./slice/podcast/currentPlaingPodcast";
import sharablePodcastSlice from "./slice/podcast/sharablePodcast";
import ProfileSlice from "./slice/profile";
// import UserProfile from "./slice/userProfile";
import CurrentFollowPreivewSlice from "./slice/follow/userFollowPreview";

import TrendingPodcastSlice from "./slice/podcast/TrendingPodcast";
import PopularPodcast from "./slice/podcast/PopularPodcast";
import UserPodcast from "./slice/podcast/UserPodcast";
import PopularPeopleSlice from "./slice/people/popularPeople"

import FollowFollowingSlice from './slice/follow/userFollowerFollowing';


import UserProfileSlice from "./slice/user-profile/UserProfile";
import UserFollowSlice from "./slice/user-profile/UserFollowers"

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    config: Appconfig,
    chat: chatSlice,
    currentPodcast: CurrentPodcastSlice,
    sharablePodcast: sharablePodcastSlice,
    profile: ProfileSlice,
    // userCustomProfile: UserProfile,
    currentFollowPreview: CurrentFollowPreivewSlice,


    userProfile : UserProfileSlice,
    userFollow : UserFollowSlice,


    trendingPodcast: TrendingPodcastSlice,
    popularPodcast : PopularPodcast,
    myPodcast : UserPodcast,
    populars: PopularPeopleSlice,
    follow : FollowFollowingSlice
  },
});

export default store;
